var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Histórico da Certidão"),
      ]),
      _c("v-card", [
        _c(
          "div",
          { attrs: { id: "cnd-history" } },
          [
            _c("v-data-table", {
              staticClass: "my-awesome-table",
              attrs: {
                headers: _vm.headers,
                items: _vm.historico,
                options: _vm.options,
                "server-items-length": _vm.totalItems,
                loading: _vm.myloadingvariable,
                "footer-props": {
                  "items-per-page-options": [10, 25, 50],
                },
              },
              on: {
                "update:options": function ($event) {
                  _vm.options = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "item.cnpj",
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm._f("cnpj")(item.cnpj)) + " "),
                    ]
                  },
                },
                {
                  key: "item.time_issuance",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("date")(item.time_issuance)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.expiration_date",
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("date")(item.expiration_date)) + " "
                      ),
                    ]
                  },
                },
                {
                  key: "item.pendencyType",
                  fn: function ({ item }) {
                    return [
                      item.pendencyType != "UNAVAILABLE"
                        ? _c(
                            "v-chip",
                            _vm._g(
                              { class: { "v-chip--clickable": true } },
                              {
                                click: () =>
                                  _vm.downloadPendency(item.id_pendency_file),
                              }
                            ),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("pendency")(item.pendencyType)
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c("v-chip", [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("pendency")(item.pendencyType))
                            ),
                          ]),
                    ]
                  },
                },
                {
                  key: "item.actions",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "mr-2",
                            attrs: {
                              disabled: item.id_cnd_file == null,
                              small: "",
                              id: "btnDownload",
                            },
                          },
                          item.id_cnd_file != null
                            ? { click: () => _vm.downloadCertidao(item) }
                            : {}
                        ),
                        [_vm._v(" mdi-download ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }