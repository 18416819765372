<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Histórico da Certidão</h2>

    <v-card>
      <div id="cnd-history">
        <v-data-table
          class="my-awesome-table"
          :headers="headers"
          :items="historico"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="myloadingvariable"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
        >
          <template v-slot:item.cnpj="{ item }">
            {{ item.cnpj | cnpj }}
          </template>
          <template v-slot:item.time_issuance="{ item }">
            {{ item.time_issuance | date }}
          </template>
          <template v-slot:item.expiration_date="{ item }">
            {{ item.expiration_date | date }}
          </template>
          <template v-slot:item.pendencyType="{ item }">
            <v-chip
              v-if="item.pendencyType != 'UNAVAILABLE'"
              v-bind:class="{ 'v-chip--clickable': true }"
              v-on="{ click: () => downloadPendency(item.id_pendency_file) }"
            >
              {{ item.pendencyType | pendency }}
            </v-chip>
            <v-chip v-else> {{ item.pendencyType | pendency }}</v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              :disabled="item.id_cnd_file == null"
              small
              class="mr-2"
              v-on="item.id_cnd_file != null ? { click: () => downloadCertidao(item) } : {}"
              id="btnDownload"
              >
              mdi-download
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-main>
</template>

<script>
import certidaoService from '@/services/certidaoService.js';
import gedService from '@/services/gedService.js';
import debounce from 'lodash/debounce';

export default {
  name: 'CertidaoHistorico',
  components: {},
  props: {
    idCertidao: {
      required: true,
    },
  },

  data() {
    return {
      myloadingvariable: false,

      options: {},
      historico: [],
      historicoCertidaoResponse: [],
      totalItems: 0,
      headers: [
        { text: 'Empresa', value: 'name_establishment' },
        { text: 'CNPJ', value: 'cnpj' },
        { text: 'Tipo', width: '100px', value: 'certificate_type' },
        { text: 'Data da emissão', width: '150px', value: 'time_issuance' },
        { text: 'Hora da emissão', width: '150px', value: 'hour_issuance' },
        { text: 'Validade', value: 'expiration_date' },
        { text: 'Código de controle', value: 'code_control' },
        { text: 'Pendências', width: '120px', value: 'pendencyType' },
        { text: 'Download', value: 'actions', sortable: false },
      ],
    };
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.filter.time_issuance);
    },
  },

  watch: {
    options: {
      handler() {
        if (this.historico && this.historico.length === 0) this.fetch();
        else this.fetchDebounced();
      },
    },
  },

  created() {
    this.fetchDebounced = debounce(this.fetch, 300);
  },

  methods: {
    async fetch() {
      this.myloadingvariable = true;
      let response = await certidaoService.certidoes.getHistorico(this.idCertidao);
      this.historicoCertidaoResponse = response.data;

      let listado = [];
      let keys = [];
      Object.entries(this.historicoCertidaoResponse).forEach(([key, entries]) => {
        keys.push(key);
        listado.push({
          name_establishment: entries.name_establishment,
          cnpj: entries.cnpj,
          certificate_type: entries.certificate_type,
          time_issuance: entries.time_issuance != null ? new Date(entries.time_issuance) : null,
          hour_issuance: entries.time_issuance != null ? this.obterHorasDate(entries.time_issuance) : null,
          expiration_date: entries.expiration_date != null ? new Date(entries.expiration_date) : null,
          code_control: entries.code_control,
          pendencyType: entries.pendencyType,
          id_certificate: entries.id_certificate,
          id_cnd_file: entries.id_cnd_file,
          id_pendency_file: entries.id_pendency_file,
          id_historic: entries.id_historic,
        });
      });

      this.historico = listado;
      this.totalItems = listado.length;
      this.myloadingvariable = false;
    },

    async downloadCertidao(certidao) {
      let fileName = 'certidao.pdf';

      if (certidao.id_cnd_file != null) {
        let responseGed = await gedService.files.get(certidao.id_cnd_file);

        this.downloadDocument(responseGed, fileName);
      }
    },

    async downloadPendency(idPenencyFile) {
      let responseGed = '';
      let fileName = 'pendencia.pdf';
      responseGed = await gedService.files.get(idPenencyFile);
      this.downloadDocument(responseGed, fileName);
    },

    downloadDocument(responseGed, fileName) {
      const linkSource = 'data:application/pdf;base64,' + responseGed.data.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    obterHorasDate(date) {
      let data = new Date(date);
      let horas = (data.getHours() < 10 ? '0' : '') + data.getHours();
      let minutos = (data.getMinutes() < 10 ? '0' : '') + data.getMinutes();
      let segundos = (data.getSeconds() < 10 ? '0' : '') + data.getSeconds();
      return horas + ':' + minutos + ':' + segundos;
    },
  },
};
</script>
<style scoped>
/* #cnd-history >>> .v-data-table-header .text-start span {
  position: fixed;
} */

.my-awesome-table {
  table-layout: fixed;
}

#btnDownload:not(:disabled){
  color: var(--v-primary-base);
}

</style>